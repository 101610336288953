[data-tootik] {
  position: relative;
}

[data-tootik]:after {
  border-radius: 7px;
  bottom: 100%;
  box-sizing: border-box;
  color: #ffffff;
  content: attr(data-tootik);
  font-family: "Century Gothic", AppleGothic, "CenturyGothic", sans-serif;
  font-size: 13px;
  font-style: normal;
  left: 50%;
  line-height: 14px;
  max-width: 320px;
  opacity: 0;
  overflow: hidden;
  padding: 6px 6px 5px 6px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  transform: translate(-50%, 12px);
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1), transform 0.3s cubic-bezier(0.73, 0.01, 0, 1);
  white-space: nowrap;
  z-index: 100000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

[data-tootik]:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-bottom-width: 0;
  content: '';
  height: 0;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: -2px;
  transform: translate(-50%, calc(-50% - 6px));
  transition: opacity 0.1s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0s;
  width: 0;
  z-index: 110000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

[data-tootik]:after {
  background: #000000;
}

[data-tootik]:before {
  border-top-color: #000000;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='right']:before {
  border-top-color: transparent;
  border-right-color: #000000;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='bottom']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #000000;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='left']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #000000;
}

[data-tootik]:hover:before, [data-tootik]:hover:after, [data-tootik]:focus:before, [data-tootik]:focus:after {
  opacity: 1;
}

[data-tootik]:hover:before, [data-tootik]:focus:before {
  transform: translate(-50%, calc(-50% - 2px));
  transition: opacity 0.1s cubic-bezier(0.73, 0.01, 0, 1) 0.1s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.1s;
}

[data-tootik]:hover:after, [data-tootik]:focus:after {
  transform: translate(-50%, -6px);
}

[data-tootik][data-tootik-conf*='right']:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-left-width: 0;
  left: auto;
  right: -6px;
  top: 50%;
  transform: translate(calc(-50% + 7px), -50%);
}

[data-tootik][data-tootik-conf*='right']:after {
  bottom: auto;
  left: 100%;
  top: 50%;
  transform: translate(-12px, -50%);
}

[data-tootik][data-tootik-conf*='right']:hover:before, [data-tootik][data-tootik-conf*='right']:focus:before {
  transform: translate(calc(-50% + 3px), -50%);
}

[data-tootik][data-tootik-conf*='right']:hover:after, [data-tootik][data-tootik-conf*='right']:focus:after {
  transform: translate(7px, -50%);
}

[data-tootik][data-tootik-conf*='bottom']:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-top-width: 0;
  bottom: -6px;
  left: 50%;
  top: auto;
  transform: translate(-50%, calc(-50% + 6px));
}

[data-tootik][data-tootik-conf*='bottom']:after {
  bottom: auto;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -12px);
}

[data-tootik][data-tootik-conf*='bottom']:hover:before, [data-tootik][data-tootik-conf*='bottom']:focus:before {
  transform: translate(-50%, calc(-50% + 2px));
}

[data-tootik][data-tootik-conf*='bottom']:hover:after, [data-tootik][data-tootik-conf*='bottom']:focus:after {
  transform: translate(-50%, 6px);
}

[data-tootik][data-tootik-conf*='left']:before {
  border-style: solid;
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 0;
  height: 0;
  left: -2px;
  top: 50%;
  transform: translate(calc(-50% - 8px), -50%);
  width: 0;
}

[data-tootik][data-tootik-conf*='left']:after {
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  transform: translate(12px, -50%);
}

[data-tootik][data-tootik-conf*='left']:hover:after, [data-tootik][data-tootik-conf*='left']:focus:after {
  transform: translate(-7px, -50%);
}

[data-tootik][data-tootik-conf*='left']:hover:before, [data-tootik][data-tootik-conf*='left']:focus:before {
  transform: translate(calc(-50% - 3px), -50%);
}

[data-tootik][data-tootik-conf*='multiline']:after {
  min-width: 180px;
  text-overflow: clip;
  white-space: normal;
  word-break: break-word;
}

[data-tootik][data-tootik-conf*='delay']:before {
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
}

[data-tootik][data-tootik-conf*='delay']:after {
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0s;
}

[data-tootik][data-tootik-conf*='delay']:hover:before, [data-tootik][data-tootik-conf*='delay']:focus:before {
  transition: opacity 0.2s cubic-bezier(0.73, 0.01, 0, 1) 0.5s, transform 0.6s cubic-bezier(0.73, 0.01, 0, 1) 0.5s;
}

[data-tootik][data-tootik-conf*='delay']:hover:after, [data-tootik][data-tootik-conf*='delay']:focus:after {
  transition: opacity 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s, transform 0.3s cubic-bezier(0.73, 0.01, 0, 1) 0.4s;
}

[data-tootik][data-tootik-conf*='shadow']:after {
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
}

[data-tootik][data-tootik-conf*='no-fading']:before, [data-tootik][data-tootik-conf*='no-fading']:after {
  transition: none;
}

[data-tootik][data-tootik-conf*='no-arrow']:before {
  display: none;
}

[data-tootik][data-tootik-conf*='square']:after {
  border-radius: 0;
}

[data-tootik][data-tootik-conf*='invert']:after {
  color: #000000;
  background: #ffffff;
}

[data-tootik][data-tootik-conf*='invert']:before {
  border-top-color: #ffffff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='invert'][data-tootik-conf*='right']:before {
  border-top-color: transparent;
  border-right-color: #ffffff;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='invert'][data-tootik-conf*='bottom']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #ffffff;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='invert'][data-tootik-conf*='left']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #ffffff;
}

[data-tootik][data-tootik-conf*='success']:after {
  background: #8bc34a;
}

[data-tootik][data-tootik-conf*='success']:before {
  border-top-color: #8bc34a;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='success'][data-tootik-conf*='right']:before {
  border-top-color: transparent;
  border-right-color: #8bc34a;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='success'][data-tootik-conf*='bottom']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #8bc34a;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='success'][data-tootik-conf*='left']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #8bc34a;
}

[data-tootik][data-tootik-conf*='info']:after {
  background: #29D2E4;
}

[data-tootik][data-tootik-conf*='info']:before {
  border-top-color: #29D2E4;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='info'][data-tootik-conf*='right']:before {
  border-top-color: transparent;
  border-right-color: #29D2E4;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='info'][data-tootik-conf*='bottom']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #29D2E4;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='info'][data-tootik-conf*='left']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #29D2E4;
}

[data-tootik][data-tootik-conf*='warning']:after {
  background: #F87D09;
}

[data-tootik][data-tootik-conf*='warning']:before {
  border-top-color: #F87D09;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='warning'][data-tootik-conf*='right']:before {
  border-top-color: transparent;
  border-right-color: #F87D09;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='warning'][data-tootik-conf*='bottom']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #F87D09;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='warning'][data-tootik-conf*='left']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #F87D09;
}

[data-tootik][data-tootik-conf*='danger']:after {
  background: #e91e63;
}

[data-tootik][data-tootik-conf*='danger']:before {
  border-top-color: #e91e63;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='danger'][data-tootik-conf*='right']:before {
  border-top-color: transparent;
  border-right-color: #e91e63;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='danger'][data-tootik-conf*='bottom']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #e91e63;
  border-left-color: transparent;
}

[data-tootik][data-tootik-conf*='danger'][data-tootik-conf*='left']:before {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #e91e63;
}

[data-tootik][data-tootik='']:before, [data-tootik][data-tootik='']:after {
  display: none;
}
