// Misc
$font-size: 13px;
$font-family: 'Century Gothic',AppleGothic,'CenturyGothic',sans-serif;
$transition-style: cubic-bezier(0.73, 0.01, 0, 1);
$border-width: 4px;
$z-index: 110000;
$arrow-position: 6px;

// Colors
$color-default: #000000;
$color-invert: #ffffff;
$tootik-default: $color-default;
$tootik-invert: $color-invert;
$tootik-success: #8bc34a;
$tootik-info: #29D2E4;
$tootik-warning: #F87D09;
$tootik-danger: #e91e63;
